export default {
	methods: {
		openRouter(path) {
			const fullUrl = this.$router.resolve({
				path
			}).href
			window.open(fullUrl, '_blank')
		}
	}
}
