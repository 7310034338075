<template>
	<header id="main-header" class="main-header">
		<div class="header-left">
			<a href="/" class="flex flex-row align-items-center">
				<div class="main-logo"></div>
				<div class="campany-name">
					<div class="text d-flex flex-row align-items-center justify-content-between w-100"><span>G</span><span>W</span><span>O</span></div>
					<div class="line" style="display:none;"></div>
					<div class="text d-flex flex-row align-items-center justify-content-between w-100"><span>汲悟科技</span></div>
				</div>
			</a>
		</div>
		
		<div class="header-right">
			<!-- <el-button type="primary" round @click="openRouter('/pages/product')">产 品</el-button> -->
			<el-button type="primary" round>登陆｜注册</el-button>
		</div>
	</header>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	export default {
		mixins: [Common]
	}
</script>

<style lang="scss" scoped>
	// Varibles
	$moble: 768px;
	// Scss
	.main-header {
		height: 80px;
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		background-color: unset;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding:20px;
		.header-left {
			a {
				display: flex;
				flex-direction: row;
				align-items: center;
				.campany-name {
					margin-left: 10px;
					display: flex;
					flex-direction: column;
					align-items: center;
					.text {
						font-size: 14px;
						color: #F8FDBD;
					}
					.line {
						height: 2px;
						width: 100%;
						background: linear-gradient(to right, var(--linear-gradient-left), var(--linear-gradient-right));
					}
					&>div:nth-child(1) {
						display: none !important;
					}
					&>div:nth-child(2) {
						display: none !important;
					}
					&>div:nth-child(3) {
						font-size: 16px;
						width: 65px !important;
					}
				}
			}
		}
		.header-right button {
			color: #004767;
			min-width: 60px;
			font-weight: 700;
			border: 4px solid transparent;
			background-color: var(--button-bg-color);
		}
	}
	@media screen and (max-width: $moble){
		.main-header {
			height: 60px;
			padding:15px;
			.header-left {
				a {
					.main-logo {
						width: 30px;
						height: 30px;
					}
					.campany-name {
						.text {
							font-size: 12px;
						}
						&>div:nth-child(3) {
							font-size: 13px;
							width: 53px !important;
						}
					}
				}
			}
			.header-right button {
				// margin-left: 15px;
				min-width: 40px;
				border: 0px solid transparent;
				font-size: 12px;
				padding: 5px 10px;
				// transform: scale(0.7, 0.7);
			}
		}
	}
</style>