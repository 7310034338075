<template>
	<section id="home-banner-3">
		<div class="banner-bg gwo-bg">
		</div>
		<div class="banner-container">
			<div class="banner-img gwo-bg">
				<div class="banner-info">
					<div class="title">吉物安途</div>
					<div class="subtitle">
						<span>跨</span>
						<span>境</span>
						<span>普</span>
						<span>运</span>
						<span>及</span>
						<span>海</span>
						<span>外</span>
						<span>仓</span>
						<span>保</span>
						<span>险</span>
					</div>
					<div>
						<div class="banner-btn gwo-bg" />
						<div class="banner-btn-info"><span>汲悟科技</span>为此保险提供科技赋能</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	export default {
		mixins: [Common]
	}
</script>

<style lang="scss" scoped>
	$mobile: 768px;
	$tablet: 1000px;
	$banner-img: url('/img/banner3/banner3.png');
	$banner-bg-img: url('/img/banner3/banner3-bg.png');
	$banner-right-arrow-img: url('/img/banner3/right-arrow.png');
	$banner-btn-img: url('/img/banner3/banner3-btn.png');
	$banner-finger-img: url('/img/banner3/finger.png');
	
	#home-banner-3 {
		position: relative;
	
		.banner-bg {
			// background-color: #3d69a2;
			background-image: $banner-bg-img;
			height: 700px;
		}
	
		.banner-container {
			position: absolute;			
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			.banner-img {
				background-image: $banner-img;
				width: 1000px;
				height: 479px;
				.banner-info {
					display: inline-block;
					height: 100%;
					.title {
						font-size: 90px;
						font-weight: 700;
						color: white;
						padding-top: 130px;
						line-height: 100px;
					}
					.subtitle {
						span {
							font-size: 34px;
							color: white;
						}
						padding-left: 10px;
						display: flex;
						flex-direction: row;
						justify-content: space-between;
						position: relative;
					}
					.subtitle::after {
						content: "";
						display: block;
						position: absolute;
						top: 10px;
						right: -45px;
						height: 50px;
						width: 40px;
						background-image: $banner-right-arrow-img;
						background-repeat: no-repeat;
						background-size: contain;
					}
					.banner-btn {
						background-image: $banner-btn-img;
						width: 80px;
						height: 40px;
						cursor: pointer;
						margin-top: 70px;
					}
					.banner-btn-info {
						background-color: rgb(224, 225, 17);
						color: black;
						display: inline-block;
						margin-top: 20px;
						border-radius: 15px;
						line-height: 30px;
						padding: 0 15px 0 50px;
						position: relative;
						span {
							font-weight: 700;
						}
					}
					.banner-btn-info::before {
						content: "";
						display: block;
						position: absolute;
						top: 5px;
						left: 20px;
						height: 20px;
						width: 30px;
						background-image: $banner-finger-img;
						background-repeat: no-repeat;
						background-size: contain;
					}
				}
			}
		}
	}
	@media screen and (max-width: $tablet) {
		#home-banner-3 {
			.banner-bg {
				// height: 400px;
				height: 480px;
			}
			.banner-container {
				.banner-img {
					width: 720px;
					height: 345px;
					.banner-info {
						.title {
							font-size: 60px;
							padding-top: 90px;
							line-height: 70px;
						}
						.subtitle {
							span {
								font-size: 20px;
							}
							padding-left: 5px;
						}
						.subtitle::after {
							top: 6px;
							right: -30px;
							height: 30px;
							width: 25px;
						}
						.banner-btn {
							width: 65px;
							height: 30px;
							margin-top: 50px;
						}
						.banner-btn-info {
							font-size: 12px;
							margin-top: 20px;
							border-radius: 12px;
							line-height: 24px;
							padding: 0 15px 0 40px;
						}
						.banner-btn-info::before {
							top: 4px;
							left: 17px;
							height: 16px;
							width: 24px;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: $mobile) {
		#home-banner-3 {
			.banner-bg {
				// height: 400px;
				height: 480px;
			}
	
			.banner-container {
				padding-left: 25px;
				justify-content: flex-start;
				.banner-img {
					min-width: 620px;
					width: calc(100vw - 50px);
					height: 300px;
					background-position: left;
					.banner-info {
						.title {
							font-size: 40px;
							padding-top: 80px;
							line-height: 50px;
						}
						.subtitle {
							span {
								font-size: 14px;
							}
							padding-left: 5px;
						}
						.subtitle::after {
							top: 3px;
							right: -24px;
							height: 20px;
							width: 20px;
						}
						.banner-btn {
							width: 55px;
							height: 26px;
							margin-top: 50px;
						}
						.banner-btn-info {
							font-size: 12px;
							margin-top: 20px;
							border-radius: 12px;
							line-height: 24px;
							padding: 0 15px 0 40px;
						}
						.banner-btn-info::before {
							top: 4px;
							left: 17px;
							height: 16px;
							width: 24px;
						}
					}
				}
			}
		}
	}
</style>