import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css';
import App from './App.vue'
import router from './router';
import store from './store';
// Import css / scss
import "./assets/css/bootstrap.min.css"
import "./assets/css/main.scss"

Vue.config.productionTip = false
Vue.use(ElementUI, { size: 'small'})
Vue.use(VueAwesomeSwiper)

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
