<template>
	<section id="home-banner">
		<div class="banner-bg gwo-bg">
		</div>
		<div
			class="banner-container position-absolute w-100 h-100 d-flex flex-row align-items-center justify-content-center">
			<div class="banner-content w-100">
				<div class="d-flex flex-row justify-content-between">
					<div style="flex:1;"></div>
					<div class="main-logo">
					</div>
					<div style="flex:1;" class="center-divider"></div>
					<div>
						<div class="text">
							<span>汲</span>
							<span>悟</span>
							<span>科</span>
							<span>技</span>
							<span>助</span>
							<span>力</span>
							<span>金</span>
							<span>融</span>
							<span>机</span>
							<span>构</span>
						</div>
						<div class="second-text">为跨境企业提供金融服务</div>
						<div class="sub-text">【因为专注，所以专业】</div>
					</div>
					<div style="flex:1;"></div>
				</div>
			</div>
		</div>
		<div class="detail-icon-container">
			<div class="detail-icon gwo-bg"></div>
		</div>
	</section>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	export default {
		mixins: [Common]
	}
</script>

<style lang="scss" scoped>
	$mobile: 768px;
	$tablet: 1000px;
	$banner-img: url('/img/banner.jpg');
	$banner-bg-img: linear-gradient(to right, rgba(0, 71, 103, 0.8), rgba(209, 222, 102, 0.8), rgba(255, 255, 255, 0.8));
	$arrow-img: url('/img/right-arrow.svg');
	
	#home-banner {
		position: relative;
	
		.banner-bg {
			background-image: $banner-img;
			height: 700px;
		}
	
		.banner-container {
			top: 0;
			left: 0;
	
			.banner-content {
				// background-image: linear-gradient(to right, #004767, #d1de66, #ffffff);
				background-image: $banner-bg-img;
				padding: 30px 0;
	
				.main-logo {
					width: 100px;
					height: 100px;
				}
	
				&>div {
					margin: 0 auto;
				}
	
				.center-divider {
					max-width: 100px;
				}
	
				.text {
					font-weight: 700;
					color: #000;
					font-size: 36px;
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: space-between;
				}
	
				.second-text {
					font-weight: 700;
					color: #000;
					font-size: 24px;
				}
	
				.sub-text {
					font-weight: 700;
					font-weight: 700;
					font-size: 18px;
					text-align: right;
					transform: translateX(10px);
				}
			}
		}
	
		.detail-icon-container {
			position: absolute;
			height: 40px;
			padding: 10px 0;
			right: 25px;
			bottom: 25px;
			cursor: pointer;
	
			.detail-icon {
				background-image: $arrow-img;
				width: 65px;
				height: 20px;
			}
		}
	}
	@media screen and (max-width: $tablet) {
		#home-banner {
			.banner-bg {
				// height: 400px;
				height: 480px;
			}
		}
	}
	@media screen and (max-width: $mobile) {
		#home-banner {
			.banner-bg {
				// height: 400px;
				height: 480px;
			}
	
			.banner-container {
				.banner-content {
					padding: 20px 0;
	
					.main-logo {
						width: 60px;
						height: 60px;
					}
	
					.center-divider {
						max-width: 50px;
					}
	
					.text {
						font-size: 24px;
					}
	
					.second-text {
						font-size: 16px;
					}
	
					.sub-text {
						font-size: 14px;
					}
				}
			}
	
			.detail-icon-container {
				.detail-icon {
					width: 48px;
					height: 15px;
				}
			}
		}
	}
</style>