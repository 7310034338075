<template>
	<section id="product-section-1">
		<div class="context-container">
			<div class="banner-title">
				<div class="banner-title-header">本产品由<span>GWO提供科技赋能</span></div>
				<div class="title">跨境电商全链条货物保障险</div>
				<div class="subtitle">「无论身处何地 保您高枕无忧」</div>
				<el-button type="primary" round>立即投保</el-button>
			</div>
			<div class="related-campany">
				<div class="campany-info anda">
					<div class="title">安达保险</div>
					<div class="subtitle">全球市值最高的上市财险和责任险公司</div>
				</div>
				<div class="campany-info huatai">
					<div class="title">华泰保险</div>
					<div class="subtitle">安达控股的国内综合性金融集团</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	export default {
		mixins: [Common]
	}
</script>

<style lang="scss" scoped>
	$mobile: 768px;
	$tablet: 1000px;
	$product-banner-img: url('/img/product-banner.png');
	$product-banner-dot-img: url('/img/product-banner-dot.png');
	$product-banner-gwo-water-print-img: url('/img/product-banner-gwo-water-print.png');
	$product-banner-t-img: url('/img/product-banner-t.png');
	$product-anda-img: url('/img/anda.png');
	$product-huatai-img: url('/img/huatai.png');
	
	#product-section-1 {
		position: relative;
		padding-top: 80px;
		height: 700px;
		background: linear-gradient(to bottom, #2C3F44, #50635C, #7F927D, #B0C29D, #E1F2BE);
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		.context-container {
			background: linear-gradient(to bottom, #91D0B4, #B5D6C0, #C5DDC3);
			width: $tablet;
			height: 480px;
			position: relative;
	
			.banner-title {
				height: 50%;
				max-width: 50%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				padding-left: 30px;
				transform: translateY(30px);
	
				.banner-title-header {
					box-shadow: 0 0 0 1px black;
					line-height: 20px;
					border-radius: 10px;
					width: max-content;
					padding: 0px 10px;
					font-size: 10px;
					background-color: white;
					margin-bottom: 15px;
	
					span {
						display: inline-block;
						background-color: yellow;
						box-shadow: 0 0 0 0.1px yellow;
						padding: 0px 5px;
						line-height: 18px;
						border-radius: 10px;
						font-weight: 700;
						margin-left: 5px;
						letter-spacing: 1.5px;
					}
				}
	
				.title {
					font-weight: 700;
					font-size: 38px;
				}
	
				.subtitle {
					font-weight: 700;
					font-size: 18px;
					margin-bottom: 20px;
					transform: translateX(-10px);
				}
	
				button.el-button {
					max-width: 80px;
					background-color: yellow;
					color: black;
				}
			}
		}
	
		.context-container::after {
			content: "";
			display: block;
			position: absolute;
			top: -50px;
			right: -60px;
			height: 512px;
			width: 560px;
			background-image: $product-banner-img;
			background-repeat: no-repeat;
			background-size: contain;
		}
	
		.context-container::before {
			content: "";
			display: block;
			position: absolute;
			top: calc(50% - 80px);
			left: calc(50% - 250px);
			width: 50%;
			height: 50%;
			background-image: $product-banner-dot-img;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
		}
	
		.related-campany {
			max-width: 600px;
			height: 50px;
			position: absolute;
			background-color: rgba(255, 255, 255, 0.7);
			bottom: -40px;
			z-index: 2;
			width: 70%;
			border: 1px dotted #000;
			border-radius: 25px;
			left: 15%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-around;
			padding: 10px;
	
			.campany-info {
				flex: 1;
				padding-left: 10px;
				margin-left: 50px;
	
				.title {
					font-size: 14px;
					font-weight: 700;
				}
	
				.subtitle {
					font-size: 10px;
				}
	
				position: relative;
			}
	
			.campany-info::before {
				content: '';
				/* 伪元素需要内容来生成盒子模型 */
				position: absolute;
				/* 绝对定位来覆盖元素的左侧 */
				top: 0;
				/* 顶部对齐 */
				left: -2px;
				/* 左侧对齐 */
				bottom: 0;
				/* 底部对齐 */
				width: 4px;
				/* 设置渐变线的宽度 */
				background: linear-gradient(to bottom, #804EF8, #39DBE2);
			}
	
			.anda::after {
				content: "";
				display: block;
				position: absolute;
				background-image: $product-anda-img;
				left: -48px;
				top: 0;
				bottom: 0;
				width: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
			}
	
			.huatai::after {
				content: "";
				display: block;
				position: absolute;
				background-image: $product-huatai-img;
				left: -48px;
				top: 0;
				bottom: 0;
				width: 40px;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
			}
		}
	
		.related-campany::before {
			content: '';
			width: 46px;
			height: 52px;
			background-image: $product-banner-t-img;
			background-repeat: no-repeat;
			background-size: contain;
			position: absolute;
			left: -50px;
			bottom: 0;
		}
	}
	
	#product-section-1::before {
		content: "";
		display: block;
		position: absolute;
		top: calc(50%);
		right: calc(50% - 80px);
		height: 400px;
		width: 895px;
		background-image: $product-banner-gwo-water-print-img;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: right;
		pointer-events: none;
		z-index: 1;
	}

	@media screen and (max-width: $tablet) {
		#product-section-1 {
			padding-top: 80px;
			height: 480px;
	
			.context-container {
				width: $mobile;
				height: 300px;
	
				.banner-title {
					height: 50%;
					max-width: 50%;
					padding-left: 20px;
					transform: translateY(30px);
	
					.banner-title-header {
						line-height: 18px;
						border-radius: 9px;
						font-size: 8px;
						margin-bottom: 10px;
	
						span {
							line-height: 16px;
							border-radius: 8px;
						}
					}
	
					.title {
						font-weight: 700;
						font-size: 30px;
					}
	
					.subtitle {
						font-weight: 700;
						font-size: 14px;
						margin-bottom: 15px;
						transform: translateX(-8px);
					}
	
					button.el-button {
						max-width: 80px;
						font-size: 10px;
					}
				}
			}
	
			.context-container::after {
				content: "";
				display: block;
				top: -50px;
				right: -80px;
				height: 340px;
				width: 380px;
			}
	
			.context-container::before {
				content: "";
				display: block;
				position: absolute;
				top: calc(50% - 60px);
				left: calc(50% - 220px);
				width: 60%;
				height: 60%;
			}
	
			.related-campany {
				height: 40px;
				bottom: -30px;
				width: 70%;
				border-radius: 20px;
	
				.campany-info {
					margin-left: 40px;
	
					.title {
						font-size: 12px;
					}
	
					.subtitle {
						font-size: 8px;
					}
				}
	
				.anda::after {
					left: -40px;
					width: 30px;
				}
	
				.huatai::after {
					left: -40px;
					width: 30px;
				}
			}
	
			.related-campany::before {
				width: 36px;
				height: 40px;
				left: -40px;
			}
		}
	
		#product-section-1::before {
			top: calc(50% + 15px);
			right: calc(50% - 15px);
			height: 250px;
			width: 495px;
		}
	}
	@media screen and (max-width: $mobile) {
		#product-section-1 {
			padding-top: 60px;
			height: 480px;
			
			.context-container {
				width: calc(100vw - 40px);
				height: 300px;
	
				.banner-title {
					padding-left: 15px;
					max-width: 100%;
					position: relative;
					z-index: 10;
	
					.banner-title-header {
						line-height: 16px;
						border-radius: 8px;
						padding: 0px 8px;
						font-size: 8px;
						margin-bottom: 10px;
	
						span {
							line-height: 14px;
							border-radius: 7px;
							letter-spacing: 1px;
						}
					}
	
					.title {
						font-size: 22px;
					}
	
					.subtitle {
						font-size: 14px;
						transform: translateX(-8px);
					}
				}
			}
	
			.context-container::after {
				top: -10px;
				right: calc(0% - 150px);
				height: 300px;
				width: 327px;
				filter: blur(0.5px);
				z-index: 3;
			}
	
			.context-container::before {
				top: 25%;
				left: 80px;
				width: 490px;
				height: 210px;
				z-index: 2;
				top: 25%;
			}
	
			.related-campany {
				min-width: 300px;
				max-width: 500px;
				height: 40px;
				bottom: -30px;
				z-index: 2;
				width: 300px;
				border-radius: 20px;
				left: calc(50% - 150px);
				padding: 8px;
	
				.campany-info {
					padding-left: 8px;
					margin-left: 40px;
	
					.title {
						font-size: 10px;
						line-height: 32px;
					}
	
					.subtitle {
						font-size: 8px;
						display: none;
					}
				}
	
				.campany-info::before {
					top: 0;
					/* 顶部对齐 */
					left: -2px;
					/* 左侧对齐 */
					bottom: 0;
					/* 底部对齐 */
					width: 4px;
					/* 设置渐变线的宽度 */
				}
	
				.anda::after {
					left: -40px;
					top: 0;
					bottom: 0;
					width: 30px;
				}
	
				.huatai::after {
					left: -40px;
					top: 0;
					bottom: 0;
					width: 30px;
				}
			}
	
			.related-campany::before {
				width: 30px;
				height: 36px;
				left: -35px;
				bottom: 0;
				z-index: 6;
			}
		}
	
		#product-section-1::before {
			top: calc(50%);
			right: unset;
			height: 250px;
			width: 559px;
			left: -200px;
			z-index: 3;
		}
	}
</style>