<template>
	<div>
		<swiper :options="swiperOption" :not-next-tick="true" ref="mySwiper">
			<!-- slides -->
			<swiper-slide>
				<first-banner></first-banner>
			</swiper-slide>
			<swiper-slide>
				<third-banner></third-banner>
			</swiper-slide>
			<!-- Optional controls -->
			<div class="swiper-pagination" slot="pagination"></div>
			<div class="swiper-button-prev" slot="button-prev"></div>
			<div class="swiper-button-next" slot="button-next"></div>
			<div class="swiper-scrollbar" slot="scrollbar"></div>
		</swiper>
		<section id="home-details">
			<div class="gwo-water-print gwo-bg"></div>
			<div id="home-details-1" class="context">
				<div class="d-flex flex-row align-items-center">
					<div class="location-icon-container">
						<div class="location-icon gwo-bg"></div>
					</div>
					<div class="text">以科技赋能跨境金融</div>
				</div>
			</div>
			<div id="home-details-2" class="context d-flex flex-column">
				<div id="advantage" class="d-flex flex-row align-items-center">
					<img src="/img/menu.svg" alt="menu" class="icon" />
					<div class="title">我们的优势</div>
				</div>
				<div id="advantages" class="p-4">
					<div id="advantages-detail" class="advantages-context mb-4">
						<div class="left-context">
							<img src="/img/tip-1.png" alt="tip-1" class="icon" />
							<div class="left-context-details">
								<div class="title">
									数据优势
									<div class="sub-detail">
										通过设计完备的Restful API与主流电商平台及ERP等实现数据对接,按需订制数据模型
									</div>
								</div>
								<el-button type="primary" round class="use-now-button">立即使用</el-button>
							</div>
						</div>
						<div class="right-context">
							<div class="imgs">
								<img src="/img/tip-2.png" alt="tip-2">
							</div>
						</div>
					</div>

					<div id="advantages-risk" class="advantages-context mb-4">
						<div class="left-context">
							<img src="/img/tip-5.svg" alt="tip-5" class="icon" />
							<div class="left-context-details">
								<div class="title">
									风控模型
									<div class="sub-detail">
										将电商业务流与金融机构的风控体系有机结合,打造高度智能化的风控体系
									</div>
								</div>
								<div class="button-container">
									<el-button type="primary" round class="use-now-button">立即使用</el-button>
								</div>
							</div>
						</div>
						<div class="right-context">
							<div class="imgs">
								<img src="/img/tip-4.png" alt="tip-4">
								<!-- <img src="/img/tip-3.png" alt="tip-3"> -->
							</div>
						</div>
					</div>

					<div id="advantages-professional" class="advantages-context">
						<div class="left-context">
							<img src="/img/tip-8.png" alt="tip-8" class="icon" />
							<div class="left-context-details">
								<div class="title">
									专业能力
									<div class="sub-detail">
										公司核心团队专注于跨境领域超过十年, 深刻理解跨境金融的行业痛点
									</div>
								</div>
								<div class="button-container">
									<el-button type="primary" round class="use-now-button">立即使用</el-button>
								</div>
							</div>
						</div>
						<div class="right-context">
							<div class="imgs">
								<img src="/img/tip-9.png" alt="tip-9">
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="home-about-us" class="d-flex flex-column align-items-center">
			<div class="title">关于我们</div>
			<div class="main-logo"></div>
			<div class="divider"></div>
			<div class="subtitle">
				GWO寓意汲悟科技,<br />
				由原上海叶道核心创始团队创办, 专注于跨境相关的金融和保险科技服务,致力于以科技力量促进跨境行业蓬勃发展.
			</div>
			<div class="other-links-wrapper">
				<div class="arc-container">
				</div>
				<div class="other-links">
					<div v-for="info in infos" class="other-link">
						<div class="icon-container d-flex align-items-center justify-content-center">
							<div class="campany-link-icon gwo-bg" :class="info.icon"></div>
						</div>
						<div class="other-link-text">
							<div class="ohter-link-title">{{info.title}}</div>
							<div class="ohter-link-subtitle">{{info.subtitle}}</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	import Banners from '@/components/banner/index.js'
	import {
		swiper,
		swiperSlide
	} from 'vue-awesome-swiper'
	export default {
		mixins: [Common],
		components: {
			swiper,
			swiperSlide,
			...Banners
		},
		data() {
			return {
				infos: [{
					icon: 'campany-link-icon',
					title: '关于公司',
					subtitle: '深圳汲悟供应链有限公司'
				}, {
					icon: 'others-link-icon',
					title: '相关链接',
					subtitle: '通过这些资源了解更多信息'
				}, {
					icon: 'legal-link-icon',
					title: '法律声明',
					subtitle: '隐私协议 ｜ 服务条款'
				}],
				swiperOption: {
					// swiper options 所有的配置同swiper官方api配置
					autoplay: 5000,
					direction: 'horizontal',
					grabCursor: true,
					setWrapperSize: true,
					autoHeight: true,
					pagination: '.swiper-pagination',
					paginationClickable: true,
					prevButton: '.swiper-button-prev',
					nextButton: '.swiper-button-next',
					scrollbar: '.swiper-scrollbar',
					// mousewheelControl: true,
					// observeParents: true,
					loop: true,
					// if you need use plugins in the swiper, you can config in here like this
					// 如果自行设计了插件，那么插件的一些配置相关参数，也应该出现在这个对象中，如下debugger
					debugger: true,
					paginationBulletRender: function(swiper, index, className) {
						console.log(className);
						return '<span class="' + className + '">' + (index + 1) + '</span>';
					},
					// swiper callbacks
					// swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
					onTransitionStart(swiper) {
						console.log(swiper, 'onTransitionStart')
						console.log(swiper)
					},
					onTransitionEnd(swiper) {
						console.log(swiper, 'onTransitionEnd')
						swiper.startAutoplay()
					},
					onInit(swiper) {
						console.log("on init", swiper)
					}
					// more Swiper configs and callbacks...
					// ...
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	// Varbiles
	$mobile: 768px;
	$tablet: 1000px;
	

	$gwo-water-print-img: url('/img/gwo-water-print.png');
	$details-bg-color: #F7F9E7;
	$details-stripe-color: linear-gradient(to left, rgba(0, 71, 103, 0.8), rgba(209, 222, 102, 0.8), rgba(255, 255, 255, 0.8)); // linear-gradient(to right, #F6F8E4, #EBE6B0, #D1DA8F, #A7B98A, #759083);
	$details-bg-img: url('/img/details-bg-img.png');
	$location-icon: url('/img/location.png');

	$campany-icon: url('/img/campany-link-icon.svg');
	$others-icon: url('/img/others-link-icon.svg');
	$legal-icon: url('/img/legal-link-icon.svg');
	
	#home-details {
		position: relative;
		overflow: hidden;
		background-color: $details-bg-color;

		.context {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			&>div {
				width: $tablet;
			}
		}

		#home-details-1.context {
			min-height: 80px;
			background: $details-stripe-color;
			position: relative;
			overflow: hidden;

			&>div {
				.location-icon-container {
					border-bottom: 2px solid yellow;
					height: 40px;
					width: 40px;

					.location-icon {
						margin: 0 auto;
						background-image: $location-icon;
						width: 18px;
						height: 31px;
					}
				}

				.text {
					margin-left: 20px;
					font-weight: 700;
					font-style: normal;
					color: #000;
					font-size: 32px;
					z-index: 100;
				}
			}
		}

		#home-details-1::before {
			content: '';
			background-image: $details-bg-img;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: left top;
			position: absolute;
			left: -30px;
			top: 0;
			width: 306px;
			height: 100px;
		}

		#home-details-2.context {
			padding: 30px 0;

			#advantage {
				img.icon {
					width: 40px;
					height: 40px;
				}

				.title {
					font-weight: 700;
					font-style: normal;
					color: rgb(0, 71, 103);
					font-size: 24px;
					margin-left: 20px;
				}
			}

			#advantages {
				.advantages-context {
					position: relative;
					display: flex;
					flex-direction: row;

					.left-context {
						display: flex;
						flex-direction: row;
						align-items: flex-start;

						.icon {
							width: 40px;
							height: 40px;
							object-position: top;
							object-fit: contain;
						}

						.left-context-details {
							margin-left: 20px;
							position: relative;
							padding-bottom: 160px;
							width: 185px;

							.title {
								font-weight: 700;
								color: rgb(0, 71, 103);
								font-size: 22px;
								width: 185px;

								.sub-detail {
									font-size: 16px;
									line-height: 26px;
									padding-top: 15px;
									font-weight: 400;
								}
							}

							.use-now-button {
								color: #004767;
								min-width: 60px;
								font-weight: 700;
								border: 4px solid #CBDA63;
								background-clip: padding-box, border-box;
								background-origin: padding-box, border-box;
								background-image: linear-gradient(to right, var(--button-bg-color), var(--button-bg-color));
								z-index: 100;
								position: absolute;
								left: 0;
								bottom: 0;
								display: none;
							}
						}
					}

					.right-context {
						div.imgs {
							position: absolute;
							right: 40px;
							width: calc(100% - 100px);
							top: 0;
							transform: translate(0px, -60px);

							img {
								width: 100%;
							}
						}
					}
				}

				#advantages-detail.advantages-context {}

				#advantages-risk.advantages-context {
					flex-direction: row-reverse;

					.left-context {
						margin-top: 160px;

						.left-context-details {
							padding-bottom: 150px;
						}
					}

					div.imgs {
						right: unset;
						left: 60px;
						top: 0;
						transform: translate(0px, 0px);
					}
				}

				#advantages-professional.advantages-context {
					.left-context {
						margin-top: 60px;

						.left-context-details {
							padding-bottom: 260px;

							.title {
								width: 260px;
							}
						}
					}

					div.imgs {
						// width: calc(75%);
						top: 0;
						transform: translate(0px, 0px);
					}
				}
			}
		}

		.gwo-water-print {
			background-image: $gwo-water-print-img;
			position: absolute;
			top: 0;
			right: 0;
			width: 500px;
			height: 149px;
			transform: translate(15%, 10%);
			background-position: left;
		}
	}



	#home-about-us {
		background-color: #F3FACA;

		.title {
			font-weight: 700;
			padding: 60px 0;
			font-size: 120%;
		}

		.main-logo {
			padding-bottom: 25px;
		}

		.divider {
			width: 15px;
			height: 1px;
			background-color: #000;
			margin: 10px 0;
		}

		.subtitle {
			text-align: center;
			width: 180px;
			font-size: 80%;
			line-height: 200%;
		}

		.other-links-wrapper {
			margin-top: 50px;
			width: 100%;
			position: relative;
			overflow: hidden;

			.arc-container {
				height: 60px;
				background-color: #E8F1AE;
				clip-path: ellipse(60% 100% at bottom);
			}

			.other-links {
				background-color: #E8F1AE;
				width: 100%;
				padding: 40px 0;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-around;

				.other-link {
					background-color: #F4F8D7;
					max-width: 280px;
					height: 256px;
					flex: 1;
					border-radius: 20px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;

					.icon-container {
						width: 40px;
						height: 40px;
						background-color: yellow;
						border-radius: 50%;

						.campany-link-icon {
							background-image: $campany-icon;
							width: 80%;
							height: 60%;
						}

						.others-link-icon {
							background-image: $others-icon;
							width: 80%;
							height: 32%;
						}

						.legal-link-icon {
							background-image: $legal-icon;
							width: 80%;
							height: 40%;
						}
					}

					.other-link-text {
						display: flex;
						flex-direction: column;
						align-items: center;

						.ohter-link-title {
							font-size: 120%;
							padding: 15px 0 5px 0;
						}

						.ohter-link-subtitle {
							font-size: 80%;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width: $tablet) {
		#home-details {
			.context {
				&>div {
					width: $mobile;
				}
			}

			#home-details-1.context {
				min-height: 72px;

				&>div {
					.location-icon-container {
						height: 36px;
						width: 36px;

						.location-icon {
							width: 14px;
							height: 28px;
						}
					}

					.text {
						margin-left: 18px;
						font-size: 29px;
					}
				}
			}

			#home-details-2.context {
				padding: 30px 0;

				#advantage {
					img.icon {
						width: 30px;
						height: 30px;
					}

					.title {
						font-size: 22px;
						margin-left: 20px;
					}
				}

				#advantages {
					.advantages-context {
						.left-context {
							.icon {
								width: 30px;
								height: 30px;
							}

							.left-context-details {
								margin-left: 15px;
								padding-bottom: 160px;
								width: 160px;

								.title {
									font-size: 20px;
									width: 160px;

									.sub-detail {
										font-size: 14px;
										line-height: 24px;
									}
								}

								.use-now-button {
									min-width: 60px;
								}
							}
						}

						.right-context {
							div.imgs {
								right: 30px;
								width: calc(100% - 110px);
								top: 0;
								transform: translate(0px, 30px);

								img {
									width: 100%;
								}
							}
						}
					}

					#advantages-detail.advantages-context {}

					#advantages-risk.advantages-context {
						.left-context {
							margin-top: 115px;

							.left-context-details {
								padding-bottom: 80px;
							}
						}

						div.imgs {
							right: unset;
							left: 50px;
							top: 0;
							transform: translate(0px, 0px);
						}
					}

					#advantages-professional.advantages-context {
						.left-context {
							margin-top: 40px;

							.left-context-details {
								padding-bottom: 180px;

								.title {
									width: 260px;
								}
							}
						}

						div.imgs {
							top: 0;
							transform: translate(0px, 0px);
						}
					}
				}
			}

			.gwo-water-print {
				width: 400px;
				height: 124px;
				transform: translate(15%, 10%);
			}
		}

		#home-about-us {
			.other-links-wrapper {
				.other-links {
					.other-link {
						max-width: 220px;
						height: 185px;
						border-radius: 15px;
					}
				}
			}
		}
	}


	@media screen and (max-width: $mobile) {
		#home-details {
			.context {
				&>div {
					width: calc(100vw - 40px);
				}
			}

			#home-details-1.context {
				min-height: 64px;

				&>div {
					.location-icon-container {
						height: 24px;
						width: 24px;

						.location-icon {
							width: 12px;
							height: 20px;
						}
					}

					.text {
						margin-left: 16px;
						font-size: 22px;
					}
				}
			}

			#home-details-2.context {
				padding: 30px 0;

				#advantage {
					img.icon {
						width: 25px;
						height: 25px;
					}

					.title {
						font-size: 20px;
						margin-left: 15px;
					}
				}

				#advantages {
					.advantages-context {
						.left-context {
							flex: 1;

							.icon {
								width: 25px;
								height: 25px;
							}

							.left-context-details {
								width: 100%;
								height: 180px;

								.title {
									width: 100%;
									font-size: 18px;

									.sub-title {
										font-size: 12px;
										line-height: 20px;
									}
								}

								.use-now-button {
									font-size: 10px;
									border: 2px solid #CBDA63;
								}

								overflow: hidden;
							}

							.left-context-details::before {
								content: "";
								display: block;
								position: absolute;
								height: 100px;
								right: 0;
								bottom: 0;
								width: 50%;
								background-position: top left;
								background-repeat: no-repeat;
								background-size: cover;
								opacity: 0.3;
							}
						}

						.right-context {
							display: none;
						}
					}

					#advantages-detail.advantages-context {
						.left-context {
							.left-context-details::before {
								background-image: url('/img/tip-2-bg.png');
							}
						}
					}

					#advantages-risk.advantages-context {
						.left-context {
							margin-top: 0;

							.left-context-details {
								padding-bottom: 80px;
								width: 100%;

								.title {
									width: 100%;
								}
							}

							.left-context-details::before {
								background-image: url('/img/tip-4-bg.png');

							}
						}
					}

					#advantages-professional.advantages-context {
						.left-context {
							margin-top: 0;

							.left-context-details {
								padding-bottom: 80px;
								width: 100%;

								.title {
									width: 100%;
								}
							}

							.left-context-details::before {
								background-image: url('/img/tip-9-bg.png');
							}
						}
					}
				}
			}

			.gwo-water-print {
				width: 300px;
				height: 93px;
				transform: translate(15%, 10%);
			}
		}

		#home-about-us {
			.other-links-wrapper {
				.other-links {
					// flex-direction: column;
					flex-wrap: wrap;

					.other-link {
						max-width: 320px;
						min-width: 280px;
						height: 150px;
						border-radius: 15px;
						flex-direction: row;
						justify-content: start;
						margin: 20px;
						margin-top: 0;
						padding: 20px;

						.other-link-text {
							margin-left: 25px;
							align-items: start;
						}
					}
				}
			}
		}
	}

	// Swiper
	$swiper-button-color: ffffff;

	/*改变了颜色和加粗的样式*/
	.swiper-button-prev {
		background-image: unset;
	}
	.swiper-button-prev::after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 44px;
		width: 27px;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l4.2%2C4.2L8.4%2C22l17.8%2C17.8L22%2C44L0%2C22z'%20fill%3D'%23" +$swiper-button-color+"'%2F%3E%3C%2Fsvg%3E");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}
	.swiper-button-next {
		background-image: unset;
	}
	.swiper-button-next::after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		height: 44px;
		width: 27px;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L5%2C44l-4.2-4.2L18.6%2C22L0.8%2C4.2L5%2C0z'%20fill%3D'%23" +$swiper-button-color+"'%2F%3E%3C%2Fsvg%3E");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}

	/deep/ .swiper-pagination-bullet {
		width: 30px;
		height: 30px;
		line-height: 30px;
		background-color: rgba(211,211,211,0.5);
		color: black;
	}

	/deep/ .swiper-pagination-bullet-active {
		background-color: rgba(128,128,128,0.5);
		color: white;
	}
	
	.swiper-container /deep/ .swiper-scrollbar-drag {
	  display: none;
	}
	.swiper-container /deep/ .swiper-scrollbar {
	  opacity: 0;
	}
	@media screen and (max-width: $tablet) {
		.swiper-button-prev::after {
			height: 40px;
			width: 24px;
		}
		.swiper-button-next::after {
			height: 40px;
			width: 24px;
			right: 0;
		}
	}
	@media screen and (max-width: $mobile) {
		.swiper-button-prev::after {
			height: 35px;
			width: 22px;
			top: 5px;
		}
		.swiper-button-next::after {
			height: 35px;
			width: 22px;
			top: 5px;
		}
	}
	 
	.swiper-button-next, .swiper-button-prev {
	    opacity: 0; /* 初始隐藏 */
	    transition: opacity 0.3s ease; /* 添加过渡效果 */
	}
	
	.swiper-container:hover .swiper-button-next,
	.swiper-container:hover .swiper-button-prev {
	    opacity: 1; /* 鼠标悬停时显示 */
	}

</style>