<template>
	<div>
		<second-banner></second-banner>
		<section id="product-section-2">
			<div class="context-container">
				<div class="content copy-right">本产品最终解释权归华泰保险所有</div>
				<div class="content content-basic content-decorator">
					<div class="left-content">
						<div class="title">
							保障内容
						</div>
						<div>
							<ol>
								<li>
									所有运输工具包括汽车(厢式货车/集装箱卡车),火 车,飞机,轮船
								</li>
								<li class="mt-2">
									中国大陆到目的地第三方海外仓，保险责任最长至货物抵达目的地第三方海外仓
								</li>
							</ol>
						</div>
						<el-button type="primary" round>立即投保</el-button>
					</div>
					<div class="right-content contents-1">
					</div>
				</div>
				<div class="content content-basic">
					<div class="left-content">
						<div class="title">
							保障范围
						</div>
						<div>
							<ol>
								<li style="list-style: none;">
									覆盖欧美,东南亚,中东大多数跨境电商主流国家, 不承保国家列表请咨询我们。
								</li>
							</ol>
						</div>
						<el-button type="primary" round>立即投保</el-button>
					</div>
					<div class="right-content contents-2">
					</div>
				</div>
				<div class="content content-basic">
					<div class="left-content">
						<div class="title">
							投保方式
						</div>
						<div>
							<ol>
								<li>
									向险司购买保障额度
									<el-button type="primary" round class="seek-btn">点我咨询</el-button>
								</li>
								<li class="my-2">
									登陆GWOtech.com后台,逐单申报 (我们提供API接口,并支持与主流ERP对接)
								</li>
								<li>
									在管理后台查看您的安心资产
								</li>
							</ol>
						</div>
						<el-button type="primary" round>立即投保</el-button>
					</div>
					<div class="right-content contents-3">
					</div>
				</div>
				<div class="why-us">
				</div>
				<div class="content content-basic content-column">
					<div class="anda"></div>
					<div class="text mt-4">
						<div class="title">• 关于安达</div>
						<div class="subtitle">安达是全球市值最大的上市财产及责任保险公司之一,在54个国家和地区拥有经营机构,为不同群体的客户提供商业 和个人财产及责任保险</div>
					</div>
					<div class="text-center my-5 divider">-</div>
					<div class="huatai"></div>
					<div class="text mt-4">
						<div class="title">• 关于华泰</div>
						<div class="subtitle">华泰保险集团股份有限公司是一家集财险、寿险、资产管理、基金管理于一体的综合性金融保险集团,前身是1996 年成立的华泰财产保险</div>
					</div>
					<div class="text-center my-5 divider">-</div>
					<div class="coop"></div>
					<div class="left-top-corner"></div>
					<div class="right-top-corner"></div>
					<div class="right-bottom-corner"></div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
	import Banners from '@/components/banner/index.js'
	export default {
		components: {
			...Banners
		},
		data() {
			return {}
		}
	}
</script>

<style lang="scss" scoped>
	// Varbiles
	$mobile: 768px;
	$tablet: 1000px;
	$product-section-bg-color: #FBFEEC;
	$product-contents-t-img: url('/img/product-contents-t.png');
	$product-contents-arrow-img: url('/img/product-contents-arrow.png');
	$product-contents-1-img: url('/img/product-contents-1.png');
	$product-contents-1-1-img: url('/img/product-contents-1.svg');
	$product-contents-2-img: url('/img/product-contents-2.png');
	$product-contents-3-img: url('/img/product-contents-3.png');
	$product-contents-3-1-img: url('/img/product-contents-3-1.png');
	$product-why-us-img: url('/img/product-why-us.png');
	$product-contents-anda-img: url('/img/product-contents-anda.png');
	$product-contents-huatai-img: url('/img/product-contents-huatai.png');
	$product-product-contents-coop-img: url('/img/product-contents-coop.png');
	$product-contents-left-top-corner-img: url('/img/product-contents-left-top-corner.png');
	$product-contents-left-top-corner-1-img: url('/img/product-contents-left-top-corner-1.png');
	$product-contents-right-top-corner-img: url('/img/product-contents-right-top-corner.png');
	$product-contents-right-bottom-corner-img: url('/img/product-contents-right-bottom-corner.png');

	button.el-button {
		border: unset;
	}

	// Scss

	#product-section-2 {
		background-color: $product-section-bg-color;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 40px 0;

		.context-container {
			width: $tablet;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;

			.copy-right {
				text-align: right;
				font-size: 12px;
				font-weight: 700;
				margin-bottom: 50px;
			}

			.content {
				max-width: 700px;
				width: calc(100% - 100px);
				position: relative;

				.left-content,
				.right-content {
					flex: 1;
				}

				.left-content {
					.title {
						font-size: 24px;
						font-weight: 700;
						line-height: 40px;
						position: relative;
					}

					.title::after {
						content: '';
						background-image: $product-contents-arrow-img;
						background-repeat: no-repeat;
						background-size: cover;
						position: absolute;
						width: 24px;
						height: 25px;
						top: 7px;
						left: 105px;
					}

					ol {
						padding: 20px;
						background-color: #D9E58C;
						border-radius: 10px;

						li {
							font-size: 12px;
							line-height: 18px;

							.seek-btn {
								color: black;
								background-image: linear-gradient(to right, #F0D35D, #F0A153);
								transform: scale(0.8, 0.8);
								letter-spacing: 1px;
								font-weight: 600;
							}
						}
					}

					button.el-button {
						background-color: yellow;
						color: black;
					}
				}

				.right-content {
					position: relative;
				}

				.contents-1::before {
					content: '';
					background-image: $product-contents-1-img;
					background-repeat: no-repeat;
					background-size: cover;
					position: absolute;
					width: 194px;
					height: 176px;
					top: -100px;
					right: 0px;
				}

				.contents-1::after {
					content: '';
					background-image: $product-contents-1-1-img;
					background-repeat: no-repeat;
					background-size: contain;
					position: absolute;
					width: 94px;
					height: 28px;
					top: -20px;
					left: 10px;
					transform: rotate(190deg);
				}

				.contents-2::before {
					content: '';
					background-image: $product-contents-2-img;
					background-repeat: no-repeat;
					background-size: cover;
					position: absolute;
					width: 330px;
					height: 160px;
					top: -80px;
					left: 10px;
				}

				.contents-3::before {
					content: '';
					background-image: $product-contents-3-img;
					background-repeat: no-repeat;
					background-size: cover;
					transform: scaleX(-1);
					position: absolute;
					width: 205px;
					height: 146px;
					top: -73px;
					left: calc(50% - 100px);
				}

				.contents-3::after {
					content: '';
					background-image: $product-contents-3-1-img;
					background-repeat: no-repeat;
					background-size: cover;
					position: absolute;
					width: 47px;
					height: 51px;
					top: -15px;
					left: 50%;
				}
			}

			.content-basic {
				background-color: #EDF4C4;
				border: 2px dotted lightgray;
				border-radius: 15px;
				min-height: 300px;
				padding: 0 50px;
				margin-top: 50px;
				display: flex;
				flex-direction: row;
				align-items: center;

			}

			.content-decorator::before {
				content: '';
				background-image: $product-contents-t-img;
				position: absolute;
				width: 76px;
				height: 70px;
				left: 40px;
				top: -55px;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}

			.content-column {
				flex-direction: column;
				padding: 50px;
				border: 1px solid #EDF4C4;
				border-radius: 60px;
				margin: 120px 0;

				.anda {
					width: 87px;
					height: 87px;
					background-image: $product-contents-anda-img;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
				}

				.huatai {
					width: 105px;
					height: 85px;
					background-image: $product-contents-huatai-img;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
				}

				.text {
					width: 310px;

					.title {
						font-weight: 700;
						font-size: 14px;
						line-height: 20px;
					}

					.subtitle {
						margin-top: 5px;
						font-size: 12px;
						line-height: 20px;
					}
				}

				.coop {
					position: relative;
					height: 200px;
				}

				.coop::before {
					content: '';
					background-image: $product-product-contents-coop-img;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 291px;
					height: 188px;
					position: absolute;
					top: 0;
					left: -150px;
				}

				.left-top-corner {
					position: absolute;
					background-image: $product-contents-left-top-corner-img;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 211px;
					height: 206px;
					left: -50px;
					top: -60px;
				}

				.left-top-corner::after {
					content: '';
					position: absolute;
					background-image: $product-contents-left-top-corner-1-img;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					left: calc(50% - 20px);
					top: calc(50% - 25px);
					width: 43px;
					height: 53px;
				}

				.right-top-corner {
					position: absolute;
					background-image: $product-contents-right-top-corner-img;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 87px;
					height: 90px;
					right: 50px;
					top: -65px;
				}

				.right-bottom-corner {
					position: absolute;
					background-image: $product-contents-right-bottom-corner-img;
					background-position: center;
					background-size: contain;
					background-repeat: no-repeat;
					width: 228px;
					height: 225px;
					right: -50px;
					bottom: -60px;
				}
			}

			.why-us {
				margin-top: 80px;
				height: 75px;
				background-image: linear-gradient(to right, $product-section-bg-color, rgb(190, 222, 192), $product-section-bg-color);
				width: 100%;
				position: relative;
			}

			.why-us::before {
				content: '- WHY';
				position: absolute;
				right: calc(50% + 20px);
				font-size: 42px;
				line-height: 75px;
				letter-spacing: 1px;
				z-index: 1;
			}

			.why-us::after {
				content: 'US -';
				position: absolute;
				left: calc(50% + 20px);
				font-size: 42px;
				line-height: 75px;
				letter-spacing: 1px;
				z-index: 1;
			}
		}
	}






	@media screen and (max-width: $tablet) {

		#product-section-2 {
			.context-container {
				width: $mobile;

				.copy-right {
					margin-bottom: 40px;
				}

				.content {
					width: calc(100% - 80px);

					.left-content {
						.title {
							font-size: 20px;
							line-height: 36px;
						}

						.title::after {
							width: 20px;
							height: 21px;
							left: 95px;
						}

						ol {
							padding: 15px 20px;

							li {
								font-size: 11px;
								line-height: 16px;
							}
						}
					}
				}

				.content-basic {
					min-height: 260px;
					padding: 0 40px;
					margin-top: 40px;
				}

				.content-decorator::before {
					width: 66px;
					height: 60px;
					left: 25px;
					top: -47px;
				}

				.content-column {
					padding: 40px;
					border-radius: 50px;
					margin: 100px 0;

					.anda {
						width: 80px;
						height: 80px;
					}

					.huatai {
						width: 94px;
						height: 76px;
					}

					.left-top-corner {
						width: 190px;
						height: 185px;
						left: -35px;
						top: -45px;
					}

					.left-top-corner::after {
						left: calc(50% - 18px);
						top: calc(50% - 22px);
						width: 39px;
						height: 48px;
					}

					.right-top-corner {
						width: 79px;
						height: 81px;
						right: 45px;
						top: -55px;
					}

					.right-bottom-corner {
						width: 206px;
						height: 203px;
						right: -35px;
						bottom: -45px;
					}
				}

				.why-us {
					margin-top: 60px;
					height: 70px;
				}

				.why-us::before {
					right: calc(50% + 10px);
					font-size: 38px;
					line-height: 70px;
				}

				.why-us::after {
					left: calc(50% + 10px);
					font-size: 38px;
					line-height: 70px;
				}
			}
		}
	}


	@media screen and (max-width: $mobile) {

		#product-section-2 {
			padding: 20px 0;

			.context-container {
				width: calc(100% - 40px);

				.copy-right {
					font-size: 12px;
					margin-bottom: 30px;
				}

				.content {
					width: calc(100%);

					.left-content,
					.right-content {
						flex: 1;
					}

					.left-content {
						min-width: 220px;
						max-width: 300px;
						z-index: 1;
					}

					.right-content {
						flex: unset;
					}

					.left-content {
						.title {
							font-size: 18px;
							line-height: 30px;
						}

						.title::after {
							width: 20px;
							height: 21px;
							top: 5px;
							left: 80px;
						}

						ol {
							padding: 15px 20px;
							border-radius: 10px;

							li {
								font-size: 10px;
								line-height: 15px;

								.seek-btn {
									transform: scale(0.7, 0.7);
								}
							}
						}

						button.el-button {
							background-color: yellow;
							color: black;
						}
					}

					.right-content {
						position: unset;
					}

					.contents-1::before {
						opacity: 0.3;
						width: unset;
						height: unset;
						top: 20px;
						bottom: 20px;
						left: 0;
						right: 20px;
						background-size: contain;
						background-position: right;
					}

					.contents-1::after {
						display: none;
					}

					.contents-2::before {
						opacity: 0.3;
						width: unset;
						height: unset;
						top: 20px;
						bottom: 20px;
						left: 0;
						right: 20px;
						background-size: contain;
						background-position: right;
					}

					.contents-3::before {
						opacity: 0.3;
						width: unset;
						height: unset;
						top: 20px;
						bottom: 20px;
						left: 0;
						right: 20px;
						background-size: contain;
						background-position: left;
					}

					.contents-3::after {
						// filter: blur(0.5px);
						opacity: 0.3;
						width: 57px;
						height: 61px;
						top: calc(50% - 25px);
						left: unset;
						right: 100px;
					}
				}

				.content-basic {
					border-radius: 10px;
					min-height: 250px;
					padding: 20px;
					margin-top: 20px;
				}

				.content-decorator::before {
					width: 50px;
					height: 44px;
					left: 20px;
					top: -35px;
				}

				.content-column {
					width: calc(100% - 40px);
					flex-direction: column;
					padding: 30px 20px;
					border-radius: 30px;
					margin: 100px 0;

					.anda {
						width: 50px;
						height: 50px;
					}

					.huatai {
						width: 62px;
						height: 50px;
					}

					.text {
						max-width: 310px;
						width: unset;

						.title {
							font-size: 12px;
							line-height: 18px;
						}

						.subtitle {
							font-size: 10px;
							line-height: 15px;
						}
					}

					.coop {
						position: relative;
						height: 200px;
					}

					.coop::before {
						width: 250px;
						height: 161px;
						left: -125px;
					}

					.left-top-corner {
						width: 120px;
						height: 117px;
						left: -20px;
						top: -20px;
					}

					.left-top-corner::after {
						left: calc(50% - 20px);
						top: calc(50% - 25px);
						width: 33px;
						height: 43px;
					}

					.right-top-corner {
						width: 57px;
						height: 60px;
						right: 40px;
						top: -45px;
					}

					.right-bottom-corner {
						width: 120px;
						height: 118px;
						right: -20px;
						bottom: -20px;
					}

					.divider {
						margin: 20px 0;
					}
				}

				.why-us {
					margin-top: 50px;
					height: 45px;
				}

				.why-us::before {
					right: calc(50% + 8px);
					font-size: 30px;
					line-height: 45px;
				}

				.why-us::after {
					left: calc(50% + 8px);
					font-size: 30px;
					line-height: 45px;
				}
			}
		}


	}
</style>